<template>
  <div id="storeManage">
    <a-form layout="inline">
      <a-form-item>
        <a-select v-model="searchParams.selectType" style="width: 120px" :form="form">
          <a-select-option
            v-for="item in optionarr"
            :value="item.value"
            :key="item.value"
          >{{ item.label }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-input v-model.trim="searchParams.name" :placeholder="`请输入${currentFieldLabel}`" allowClear />
      </a-form-item>
      <a-form-item>
        <a-button @click="search" type="primary" html-type="submit" :loading="loading">查询</a-button>
      </a-form-item>
      <div class="ptb-10">
        <a-button type="primary" @click="operateShop('add')" v-auth="'basic_douyin_shop_add'">添加店铺</a-button>
      </div>
    </a-form>

    <a-modal :destroyOnClose="true" v-model="isOperateShop" :title="modalTitle">
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="operateShopRequest">
        <a-form-item label="店铺名称">
          <a-input :maxLength=30 v-model="shopName" v-decorator="['note', { rules: [{ required: true, message: '请输入店铺名称' }], initialValue: shopName }]" />
        </a-form-item>
        <a-form-item label="编码">
          <a-input :maxLength=30 v-model="shopCode" />
        </a-form-item>
        <a-form-item class="form_btn"><a-button class="mr-30" @click="isOperateShop=false;">取消</a-button>
          <a-button type="primary" html-type="submit">确定</a-button>
        </a-form-item>
      </a-form>
    </a-modal>

    <a-table :scroll="{ y: tableScrollY }" v-auth="'account_manage_list'" :rowKey="record => record.id" :columns="Columns" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ filterConfirm: '暂无数据' }" @change="handleTableChange">
      <template slot="deadlineTime" slot-scope="record">
        <span>{{record.apply_deadline_time ? record.apply_deadline_time.substr(0, 16) : ''}}</span>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button type="primary" size="small" @click="operateShop('edit',record)" v-auth="'basic_douyin_shop_modify'">编辑</a-button>
      </template>
    </a-table>

  </div>
</template>

<script>
import { shopList, addShop, editShop } from "@/service/operate";
import { checkPer, value2label } from "@/utils/index";

/**
 * @Author: 矢车
 * @Date: 2020-07-07 10:08:51
 * @Description: 表格显示字段配置
 */
const Columns = [
  {
    title: "店铺名称",
    dataIndex: "shop_title",
    align: "left",
  },
  {
    title: "编码",
    dataIndex: "code",
    align: "center",
    width: 160
  },
  {
    title: "操作",
    align: "center",
    width: 120,
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  name: "special",
  data() {
    return {
      optionarr: [
        { label: '店铺名称', value: 'title' },
        { label: '编码', value: 'code' },],
      tableScrollY: 600,
      loading: false,
      modalTitle: "新建店铺", //  新增修改弹层
      presentStatus: "add", // 当前状态是新增还是修改
      form: this.$form.createForm(this, { name: "coordinated" }),
      isOperateShop: false,
      shopName: "",
      shopCode: "",
      recordRow: "", //  点击编辑那一行的数据
      Columns,
      listLoading: true,
      list: [],
      searchParams: {
        selectType: "title",
        name: ""
      }, //  搜索字段
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      }
    };
  },
  computed: {
    // input的placeholder
    currentFieldLabel() {
      return value2label(this.searchParams.selectType, this.optionarr);
    },
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 345;
  },
  mounted() {
    this.getTopicList();
  },
  methods: {
    //  清空表单数据
    clearData() {
      this.shopName = "";
      this.shopCode = "";
    },
    // 操作店铺
    async operateShopRequest(e) {
      e.preventDefault();
      this.form.validateFields(async (err, values) => {
        if (!err) {
          console.log(values);

          //  当前是新增还是修改
          let status;
          if (this.presentStatus === "add") {
            status = await addShop({
              shop_title: this.shopName,
              code: this.shopCode
            });
          } else {
            status = await editShop(this.recordRow.id, {
              shop_title: this.shopName,
              code: this.shopCode
            });
          }
          const { err, res } = status;
          if (!err) {
            if (res.success) {
              this.$message.success(
                this.presentStatus === "add"
                  ? "新增店铺成功！"
                  : "修改店铺成功！"
              );
              this.clearData();
              this.isOperateShop = false;
              this.getTopicList();
            }
          }
        }
      });
    },
    //  搜索
    search() {
      let shop_title =
        this.searchParams.selectType === "title" ? this.searchParams.name : "";
      let code =
        this.searchParams.selectType !== "title" ? this.searchParams.name : "";
      this.getTopicList(shop_title, code);
    },
    operateShop(type, record) {
      this.presentStatus = type;
      this.isOperateShop = true;
      this.clearData();

      if (type === "add") {
        this.modalTitle = "新建店铺";
      } else {
        this.modalTitle = "编辑店铺";
        this.form.setFieldsValue("note", this.shopName); //  设置值
        this.shopName = record.shop_title;
        this.shopCode = record.code;
        this.recordRow = record; //  当前点击某一行的数据
      }
    },
    //  表单验证
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    addConfirm() {
      //  添加店铺
      this.isAddShop = true;
    },
    addConfirmLoad() {
      this.handleSubmit();
    },
    addCancel() {},
    // 搜索回调
    handleSearchCallback(searchParams) {
      this.searchParams = searchParams;
      this.getTopicList();
    },
    // 获取专题列表
    async getTopicList(shop_title, code) {
      if (!checkPer("basic_douyin_shop_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      this.listLoading = true;
      const { err, res } = await shopList({
        shop_title,
        code,
        page: this.pagination.current,
        page_size: this.pagination.pageSize
      });
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          this.list = res.data.results;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 点击查看报名详情
    handleShowTopicPreview(id) {
      this.$router.push({
        path: "/admin/operate/topicRegDetail",
        query: { id }
      });
    },
    // 分类
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getTopicList();
    }
  }
};
</script>

<style lang="less" scoped>
#storeManage {
  .ptb-10 {
    padding: 10px 0;
  }
}
.form_btn {
  display: flex;
  justify-content: center;
}

.mr-30 {
  margin-right: 30px;
}
</style>

<style scoped>
>>> .ant-modal-footer {
  display: none;
}
</style>

